<template>
  
    <div>
      <v-card>
        <v-card-title>Listado de Clientes</v-card-title>
        <v-card-text>
          <botonera :botones="botones" alinear="right"
        @crear="dialog = !dialog"
        @cargar="upload = !upload"
        ></botonera>
      </v-card-text>
    </v-card>

    <datatable 
      :cabeceras="empresasListHeaders" 
      :items="empresasListBody" 
      titulo="Listado de Clientes" 
      label="Escriba Para buscar Cliente" 
      icono="list" 
      color_icono="azul"
      :tachar="true"
      :acciones="['editar','eliminar','subir','mostrar','ocultar']"
      @editar="editEmpresa($event)"
      @eliminar="deleteEmpresa($event)"
      @subir="showUploadLogo($event)"
      @mostrar="mostrar($event)"
      @ocultar="ocultar($event)">
    </datatable>

    <!-- Modal Nuevo Cliente -->
    <v-dialog
      v-model="dialog"
      width="800px"
      @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-col
              class="align-center justify-space-between"
              cols="4"
            >
              <v-text-field
                prepend-icon="mdi-barcode"
                placeholder="Rut"
                v-model="empresa.rut"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                prepend-icon="mdi-barcode"
                placeholder="Razon Social"
                v-model="empresa.razon_social"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                prepend-icon="mdi-barcode"
                placeholder="Giro"
                v-model="empresa.giro"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                prepend-icon="mdi-directions"
                placeholder="Direccion"
                v-model="empresa.direccion"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                prepend-icon="mdi-phone"
                placeholder="Fono"
                v-model="empresa.fono"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                prepend-icon="mdi-gmail"
                placeholder="Email"
                v-model="empresa.email"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
              <v-btn
                @click="closeModal"
              >Cerrar</v-btn>
              <v-btn
                color="primary"
                @click="saveEmpresa"
              >Grabar</v-btn>
        </v-card-actions>
              
      </v-card>
    </v-dialog>
    <!-- / Modal -->

    <!-- Modal upload Excel -->
    <v-dialog
      v-model="upload"
      width="600px"
    >
      <v-card>
        <v-card-title class="green darken-1">
          Subir listado de empresas desde excel
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="upload = false"
          >Cancelar</v-btn>
          <v-btn
            color="primary"
            @click="uploadEmpresas"
          >Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->

    <!-- Modal upload logo -->
    <v-dialog
      v-model="uploadLogo"
      width="600px"
    >
      <v-card>
        <v-card-title class="green darken-1">
          Subir logo de cliente
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-file-input v-model="logo" accept=".jpg, .png" label="Archivo de Imagen"></v-file-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="uploadLogo = false"
          >Cancelar</v-btn>
          <v-btn
            color="primary"
            @click="uploadLogoCliente"
          >Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    
</div>
    
</template>
<script>
  import {mapState, mapMutations} from 'vuex'
  import DataTable from '@/components/base/DataTable.vue';
  export default {
    data: () => ({
      title: 'Nuevo Cliente',
      color: 'green',
      snackbar: false,
      errors: [],
      validation_message: '',
      success: null,
      search: null,
      file: null,
      logo: null,
      dialog: false,
      upload: false,
      uploadLogo: false,
      empresaIndex: -1,
      empresa: {
        id: 0,
        rut: '',
        razon_social: '',
        giro: '',
        direccion: '',
        fono: '',
        email: '',
      },
      empresasListHeaders: [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'id',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Rut',
          align: 'center',
          sortable: true,
          value: 'rut',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Razon Social',
          align: 'center',
          sortable: true,
          value: 'razon_social',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Giro',
          align: 'center',
          sortable: true,
          value: 'giro',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Direccion',
          align: 'center',
          sortable: true,
          value: 'direccion',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Fono',
          align: 'center',
          sortable: true,
          value: 'fono',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Email',
          align: 'center',
          sortable: true,
          value: 'email',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Logo',
          align: 'center',
          sortable: true,
          value: "logo",
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '200px',
          class: "blue-grey darken-4 white-text"
        },
      ],
      empresasListBody: [],
      botones:[
        {boton:'crear',tooltip:'Nuevo Cliente'},
        {boton:'cargar',tooltip:'Carga Masiva'},
        //{boton:'eliminar',tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
      this.loadEmpresas();
    },
    methods:{
      async loadEmpresas(){
        this.mostrarLoading('Cargando listado de empresas...')
        let url = `${this.base_url}empresas`;
        await this.axios.get(url, this.headers_json).then((response)=>{
          this.empresasListBody = response.data;
          this.ocultarLoading()
        }).catch((error)=>{
          console.log(error);
          this.ocultarLoading()
        });
      },
      async saveEmpresa(){
        // validation
        this.errors = [];
        if(!this.empresa.rut){
          this.errors.push("Debe indicar rut de la empresa");
        }else if(!this.isValidRut(this.empresa.rut)){
          this.errors.push("Debe ingresar un rut válido");
        }
        if(!this.empresa.giro){
          this.errors.push("Debe indicar giro de la empresa");
        }else if(this.empresa.giro.length > 100){
          this.errors.push("Tamaño máximo 100 caracteres");
        }
        if(!this.empresa.direccion){
          this.errors.push("Debe indicar direccion de la empresa");
        }else if(this.empresa.direccion.length > 100){
          this.errors.push("Tamaño máximo 100 caracteres");
        }
        if(!this.empresa.fono){
          this.errors.push("Debe indicar fono de la empresa");
        }else if(this.empresa.fono.length > 50){
          this.errors.push("Tamaño máximo 50 caracteres");
        }
        if(!this.empresa.email){
          this.errors.push("Debe indicar email de la empresa");
        }else if(this.empresa.email.length > 100){
          this.errors.push("Tamaño máximo 100 caracteres");
        }else if(!this.isValidEmail(this.empresa.email)){
          this.errors.push("Debe ingresar un email válido");
        }
        if(!this.empresa.razon_social){
          this.errors.push("Debe indicar nombre de la empresa");
        }else if(this.empresa.razon_social.length > 40){
          this.errors.push("Tamaño máximo 40 caracteres");
        }
        if(this.errors.length > 0){
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let url = `${this.base_url}empresas`;
        let empresaData = {
          'rut': this.empresa.rut,
          'razon_social': this.empresa.razon_social,
          'giro': this.empresa.giro,
          'direccion': this.empresa.direccion,
          'fono': this.empresa.fono,
          'email': this.empresa.email,
        };
        if(this.empresa.id > 0){
          url = url + "/" + this.empresa.id;
          await this.axios.put(url, empresaData, this.headers_json).then((response)=>{
            let index = this.empresasListBody.map(empresa => {
              return empresa.id;
            }).indexOf(this.empresa.id);
            this.empresasListBody.splice(index, 1, response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
            this.validation_message = error;
            this.showSnackBar(true);
          });
        }else{
          await this.axios.post(url, empresaData, this.headers_json).then((response)=>{
            this.empresasListBody.push(response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
            this.errors = error.response.data;
            this.validation_message = this.errors.join();
            this.showSnackBar(true);
          });
        }
        this.cleanForm();
      },
      editEmpresa(item) {
        this.empresaIndex = this.empresasListBody.indexOf(item);
        this.empresa = Object.assign({}, item);
        this.dialog = true;
        this.title = "Editar Cliente";
      },
      showUploadLogo(item){
        this.empresaIndex = this.empresasListBody.indexOf(item);
        this.empresa = Object.assign({}, item);
        this.uploadLogo = true;
      },
      async deleteEmpresa(item) {
        this.$confirm('Desea borrar este empresa?').then(res => {
          if(res){
            let url = `${this.base_url}empresas/${item.id}`;
            this.axios.delete(url, this.headers_json).then((response)=>{
              console.log(response);
              const index = this.empresasListBody.indexOf(item);
              this.empresasListBody.splice(index, 1);
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async ocultar(item) {
        this.$confirm('Desea ocultar esta empresa?').then(res => {
          if(res){
            let url = `${this.base_url}empresas/ocultar/${item.id}`;
            this.axios.get(url, this.headers_json).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadEmpresas();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async mostrar(item) {
        this.$confirm('Desea mostrar esta empresa?').then(res => {
          if(res){
            let url = `${this.base_url}empresas/mostrar/${item.id}`;
            this.axios.get(url, this.headers_json).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadEmpresas();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      closeModal(){
        this.cleanForm();
        this.dialog = false;
        this.error = null;
        this.title = "Nuevo Cliente";
      },
      cleanForm(){
        this.empresa.rut = "";
        this.empresa.razon_social = "";
        this.empresa.giro = "";
        this.empresa.direccion = "";
        this.empresa.fono = "";
        this.empresa.email = "";
        this.dialog = false;
      },
      isValidRut(rut){
        let multiplo = 2;
        let arrRut = rut.split("-");
        let digito = arrRut[1];
        let mRut = arrRut[0].replaceAll(".", "");
        let suma = 0;
        for(let p = 0; p < mRut.length; p++){
          suma += (multiplo * mRut.charAt(mRut.length - p - 1));
          if(multiplo < 7){
            multiplo = multiplo + 1; 
          }else{
            multiplo = 2;
          }
        }
        let dv = 11 - (suma % 11);
        if(dv == 10) dv = 'K';
        if(dv == 11) dv = 0;
        return digito == dv;
      },
      async uploadEmpresas(){
        //this.headers.headers['Content-Type'] = "multipart/form-data";
        let url = `${this.base_url}uploads/empresas`;
        let formData = new FormData();
        formData.append("excel", this.file);
        let response = null;
        try{
          response = await this.axios.post(url, formData, this.headers_file);
          this.file = null;
          this.upload = false;
          this.loadEmpresas();
          this.showSnackBar(false);
        }catch(error){
          response = error.response;
          let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. '+ msg;
          this.showSnackBar(true);
        }
      },
      async uploadLogoCliente(){
        //this.headers.headers['Content-Type'] = "multipart/form-data";
        let url = `${this.base_url}uploads/logo`;
        let formData = new FormData();
        formData.append("logo", this.logo);
        formData.append("empresas_id", this.empresa.id);
        let response = null;
        try{
          response = await this.axios.post(url, formData, this.headers_file);
          this.logo = null;
          this.uploadLogo = false;
          this.showSnackBar(false);
          this.loadEmpresas();
        }catch(error){
          response = error.response;
          let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. '+ msg;
          this.showSnackBar(true);
        }
      },
      isValidEmail(mail){
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)){
          return (true);
        }
        return (false);
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers_file','headers_json']),
      
    },
    components:{
      'datatable':DataTable
    }
  }
</script>